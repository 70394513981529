.site-name {
	font-size: 60px;
	font-weight: 500;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	background-image: url('../../../public/rice-pile.jpg');
}

.carousel1{
	background-image: url('../../../public/rice-oil.jpg');
}
.carousel2{
	background-image: url('../../../public/rice-pile.jpg');
}
.carousel3{
	background-image: url('../../../public/naira.jpg');	
}
.carousel4{
	background-image: url('../../../public/ledger2.png');
}
.carousel1, .carousel2, .carousel3, .carousel4 {
	height: 300px;
	background-position: center;
	background-repeat: no-repeat;
	/* width:100%;
	height:100%; */
		/* background-size:cover; */
	 background-size:100%;
}
/* @media screen and (max-width:600px){
	.carousel1, .carousel2, .carousel3, .carousel4 {
		height: 300px;
	}
} */

.wrapperClassName{
    width: 350px;
	height: 300px;
    border: 1px solid goldenrod;
    border-radius: 10px;
    padding: 10px;
}
.editorClassName{
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
}
.gecko{color:rgb(154, 214, 154)}